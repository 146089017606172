import close from 'assets/icons/close.svg'
import plus from 'assets/icons/plus.svg'
import bolt from 'assets/icons/bolt.svg'
import discord from 'assets/icons/circle-discord.svg'
import dashboard from 'assets/icons/activity-chart.svg'
import entering from 'assets/icons/entering.svg'
import CE from 'assets/icons/CE.svg'
import collectiveMeditation from 'assets/icons/collective-meditations.svg'
import discordB from 'assets/icons/discord.svg'
import twitter from 'assets/icons/twitter.svg'
import contactButton from 'assets/icons/contact-button.svg'
import slideIcon from 'assets/icons/slide-icon.svg'
import book from 'assets/icons/book.svg'
import flask from 'assets/icons/flask.svg'
import graduation from 'assets/icons/graduation.svg'
import quotes from 'assets/icons/quotes.svg'
import track from 'assets/icons/track.svg'
import collective from 'assets/icons/collective.svg'
import whiteLogo from 'assets/icons/white-logo.svg'
import supporting from 'assets/icons/supporting.svg'
import accessibility from 'assets/icons/accessibility.svg'
import globalProgress from 'assets/icons/global-progress.svg'
import bws from 'assets/icons/bws.svg'
import halolab from 'assets/icons/halo-lab.svg'
import enlightning from 'assets/icons/enlightning.svg'
import neuro from 'assets/icons/neuro.svg'
import heroBG from 'assets/icons/BG.svg'
import heroBGtest from 'assets/icons/BG_test1.svg'
import heroBGtest2 from 'assets/icons/BG_test2.svg'
import heroBGtest3 from 'assets/icons/BG_test3.svg'
import download from 'assets/icons/download.svg'
import coin from 'assets/icons/coin.svg'
import REcoin from 'assets/icons/REcoin.svg'
import rightArrow from 'assets/icons/right_arrow.svg'
import teacher from 'assets/icons/teacher.svg'
import textLogo from 'assets/icons/text-logo.svg'
import floatPlus from 'assets/icons/float_plus.svg'
import star from 'assets/icons/star.svg'
import dollar from 'assets/icons/dollar.svg'
import at from 'assets/icons/at.svg'
import wallet from 'assets/icons/wallet.svg'
import headphones from 'assets/icons/headphones.svg'
import trendUp from 'assets/icons/trendup.svg'
import infinity from 'assets/icons/infinity.svg'
import conscious from 'assets/icons/conscious.svg'
import tip from 'assets/icons/tip.svg'
import x from 'assets/icons/X.svg'
import chosen from 'assets/icons/chosen.svg'
import eyeClosed from 'assets/icons/eye_closed.svg'

export default {
  conscious,
  eyeClosed,
  chosen,
  x,
  tip,
  infinity,
  trendUp,
  headphones,
  at,
  wallet,
  rightArrow,
  dollar,
  star,
  floatPlus,
  textLogo,
  teacher,
  coin,
  REcoin,
  download,
  heroBG,
  heroBGtest,
  heroBGtest2,
  heroBGtest3,
  neuro,
  enlightning,
  bws,
  halolab,
  supporting,
  accessibility,
  globalProgress,
  whiteLogo,
  collective,
  track,
  quotes,
  book,
  flask,
  graduation,
  contactButton,
  slideIcon,
  discordB,
  twitter,
  close,
  collectiveMeditation,
  CE,
  entering,
  dashboard,
  plus,
  bolt,
  discord
}
