import logo from 'assets/images/logo.png'
import headerLogo from 'assets/images/header-logo.png'
import textLogo from 'assets/images/text-logo.png'
import twitterLogo from 'assets/images/TwitterLogo.png'
import discordLogo from 'assets/images/DiscordLogo.png'
import nebula from 'assets/images/nebula.png'
import brain from 'assets/images/brain.png'
import brain2 from 'assets/images/brain-2.png'
import heroVideo from 'assets/images/hero-video.png'
import tree from 'assets/images/tree.png'
import dojo from 'assets/images/dojo.png'
//  import monk from 'assets/images/monk.png'
import monkm1 from 'assets/images/monk_m1.png'
import waves from 'assets/images/waves.png'
//  import lotus from 'assets/images/lotus.png'
// import futuref2 from 'assets/images/future_f2.png'
import futuref3 from 'assets/images/lotus-raksa3.png'
import brainLight from 'assets/images/brain-light.svg'
// import heroBG from 'assets/images/BG.png'
import heroBG1 from 'assets/images/BG_brain1_fade_trans.png'
import heroBG2 from 'assets/images/BG_brain2_fade_trans.png'
import medWave from 'assets/images/med-wave.png'
import waveBG from 'assets/images/wave-bg.png'
import menuImage from 'assets/images/menu-image.png'
import manWaves from 'assets/images/man-waves.png'
import smallMenuImage from 'assets/images/small-menu-image.png'
import smallHeroBg from 'assets/images/small-herobg.png'
import brainBg from 'assets/images/brainBG.png'
import journeyBg from 'assets/images/journeyBG.png'
import etherealBg from 'assets/images/etherealBG.png'
import transmute from 'assets/images/Transmute.png'
import ce from 'assets/images/CE.png'
import re from 'assets/images/RE.png'
import playlist from 'assets/images/playlist.png'
import collectiveMeditation from 'assets/images/collective-meditations.png'
import vote from 'assets/images/vote.png'
import roadmapHero from 'assets/images/roadmap-hero.png'
import roadmap1 from 'assets/images/roadmap1.png'
import roadmapBackground from 'assets/images/roadmap-background.png'
import contactButton from 'assets/images/contact-button.png'
import contactButton2 from 'assets/images/contact-button2.png'
import research from 'assets/images/research.png'
import technology from 'assets/images/technology1.png'
import science from 'assets/images/science.png'
import mysticscience from 'assets/images/mysticismscience3.png'
import ml from 'assets/images/ml.png'
import mri from 'assets/images/mri.png'
import eeg from 'assets/images/eeg.png'
import fusion from 'assets/images/fusion.png'
import mission from 'assets/images/mission.png'
import future from 'assets/images/future.png'
import benefits from 'assets/images/benefits.png'
import onboarding from 'assets/images/onboarding.png'
import teacheronboarding from 'assets/images/teacher-onboarding.png'
import community from 'assets/images/community1.png'
import logobrain from 'assets/images/logo-brain.png'
import interest from 'assets/images/interest.png'
import content from 'assets/images/content.png'
import ai from 'assets/images/ai.png'
import aiAssistant from 'assets/images/aiAssistant.png'
import aialanwatts from 'assets/images/aialanwatts.png'
import fair from 'assets/images/fair.png'
import dalai from 'assets/images/dalai.png'
import vission from 'assets/images/vission.png'
import missionvision from 'assets/images/mission-vision3.png'
import missionvision1 from 'assets/images/mission-vision4.png'
import missionvision2 from 'assets/images/mission-vision5.png'
import medit from 'assets/images/medit.png'
import femonk from 'assets/images/femonk.png'
import phone from 'assets/images/phone.png'
import flowers from 'assets/images/flowers.png'
import keyFunctions from 'assets/images/Key-functions.png'
import serviceLabs from 'assets/images/service-labs3.png'
// import serviceUniversity from 'assets/images/service-university.png'
import serviceUniversity1 from 'assets/images/mwuniversity.png'
import serviceCommunity from 'assets/images/services-community-rh.png'
import serviceAssistant from 'assets/images/service-ai.png'
import serviceWeb3 from 'assets/images/service-metaverse.png'
import serviceDojo from 'assets/images/service-dojo3.png'
import starsBG from 'assets/images/starsBG.png'
import starsGIF from 'assets/images/starsGIF.gif'
import scienceLG from 'assets/images/science-lg.png'
import stars from 'assets/images/stars.png'
import twinkling from 'assets/images/twinkling.png'
import twinkle from 'assets/images/twinkle.png'
import hdStars from 'assets/images/hd_stars.png'
import ceFinal from 'assets/images/ce-final.png'
import reFinal from 'assets/images/re-final.png'
import activity from 'assets/images/activity.png'
import meditate from 'assets/images/meditate.png'
import transcend from 'assets/images/transcend.png'
import levelUp from 'assets/images/levelup.png'
import conscious from 'assets/images/consciuos.png'
import earn from 'assets/images/earn.png'
import headset from 'assets/images/headset.png'
import connect from 'assets/images/connect.png'
import platform from 'assets/images/platform.png'
import rent from 'assets/images/rent.png'
import metalabs from 'assets/images/metalabs.png'
import ramzi from 'assets/images/ramzi.png'
import rafael from 'assets/images/rafael.png'
import daniel from 'assets/images/daniel.png'
import kyle from 'assets/images/kyle.png'
import teamExpand from 'assets/images/team_expand.png'
import teamClose from 'assets/images/team_close.png'
import dat from 'assets/images/dat.png'
import david from 'assets/images/david.png'
import james from 'assets/images/james.png'
import soon from 'assets/images/soon.png'
import tokenStep from 'assets/images/tokenStep.png'
import alphaStep from 'assets/images/alphaStep.png'
import alphaStep1 from 'assets/images/alphaStep1.png'
import referralStep from 'assets/images/referralStep.png'

export default {
  metalabs,
  soon,
  dat,
  david,
  james,
  teamClose,
  teamExpand,
  ramzi,
  rafael,
  daniel,
  kyle,
  rent,
  platform,
  connect,
  earn,
  headset,
  conscious,
  transcend,
  levelUp,
  starsGIF,
  meditate,
  activity,
  ceFinal,
  reFinal,
  hdStars,
  twinkle,
  twinkling,
  stars,
  scienceLG,
  serviceDojo,
  starsBG,
  serviceCommunity,
  serviceWeb3,
  serviceAssistant,
  keyFunctions,
  // serviceUniversity,
  serviceUniversity1,
  serviceLabs,
  femonk,
  phone,
  flowers,
  medit,
  vission,
  missionvision,
  missionvision1,
  missionvision2,
  dalai,
  fair,
  ai,
  aiAssistant,
  aialanwatts,
  content,
  interest,
  logobrain,
  onboarding,
  teacheronboarding,
  community,
  benefits,
  future,
  mission,
  ml,
  mri,
  fusion,
  eeg,
  science,
  mysticscience,
  contactButton2,
  research,
  technology,
  contactButton,
  roadmapHero,
  roadmap1,
  roadmapBackground,
  playlist,
  vote,
  ce,
  re,
  collectiveMeditation,
  journeyBg,
  etherealBg,
  transmute,
  brainBg,
  manWaves,
  smallHeroBg,
  smallMenuImage,
  menuImage,
  logo,
  // heroBG,
  heroBG1,
  heroBG2,
  waveBG,
  medWave,
  nebula,
  brainLight,
  //  lotus,
  // futuref2,
  futuref3,
  waves,
  headerLogo,
  discordLogo,
  twitterLogo,
  textLogo,
  dojo,
  brain,
  brain2,
  heroVideo,
  tree,
  //  monk
  monkm1,
  tokenStep,
  alphaStep,
  alphaStep1,
  referralStep
}
